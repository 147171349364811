.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  .title {
    padding-top: 1rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 0.8rem;
    font-weight: 200;
  }
  .container {
    padding: 4px 16px;
  }
  display: block;
}

.icon-line {
  display: flex;
  padding: 0;
  margin: 0;
  text-size: 0.8rem;
  font-weight: 200;

  p {
    padding: 0 !important;
  }
}

.unstyled-link {
  text-decoration: none;
  color: inherit;

  & :hover {
    text-decoration: none;
    color: inherit;
  }
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
